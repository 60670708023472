import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Contact = () => {
  return (
    <>
      <Seo pageTitle={'Contact'} />
      <Layout>
        <div className='container'>
          <div className='row mt-5'>
            <div className='offset-lg-3 col-lg-6 mb-3 text-center'>
              <h1 className='display-4 mb-5'>Get in touch</h1>
              <p>Email us at:</p>
              <a className='lead' href='mailto:info@humsathsewaleicester.co.uk'>
                info@humsathsewaleicester.co.uk
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
